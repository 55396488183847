<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>Tyre Form Department</strong>
          </CCardHeader>
          <CRow>
            <CCol md="8">
              <CCardBody>
                <CForm>
                  <MultiSelect
                    label="Departments *"
                    :options="allDepartments"
                    :hide-selected="false"
                    :searchable="true"
                    optionLabel="departmentName"
                    :disabled="mode.viewMode"
                    track-by="departmentId"
                    :track-ids.sync="tyreFormDepartment.departmentId"
                    idParamName="departmentId"
                    :itemTypeisString="false"
                    invalidFeedback="Select atleast one Department"
                  ></MultiSelect>
                </CForm>
              </CCardBody>
            </CCol>
          </CRow>

          <CCardFooter align="right">
            <CButton
              v-if="mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="primary"
              @click="editMode()"
            >
              <CIcon name="cil-pencil" /> Edit
            </CButton>

            <CButton
              v-if="!mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="success"
              @click="save()"
            >
              <CIcon name="cil-check-circle" /> Save
            </CButton>

            <CButton
              class="mr-2"
              type="Cancel"
              size="sm"
              color="danger"
              @click="cancel()"
            >
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import datetime from "vuejs-datetimepicker";
import MultiSelect from "../../containers/MultiSelect";
import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "TyreDepartment",
  data() {
    return {
      mode: {
        newMode: false,
        editMode: false,
        viewMode: false,
      },
      tyreFormDepartmentId: "",
      tyreFormDepartment: {
        tyreFormDepartmentId: "",
        departmentId: "",
      },
      allDepartments: [],
      horizontal: { label: "col-3", input: "col-9" },
    };
  },

  validations: {
    tyreFormDepartment: {
      departmentId: {
        required,
      },
    },
  },
  created() {
    if (this.$route.params.mode == "new") {
      this.mode.newMode = true;
      this.mode.viewMode = false;
      this.mode.editMode = false;

      this.getDepartments();
    }
    if (this.$route.params.mode == "view") {
      this.mode.viewMode = true;
      this.tyreFormDepartmentId = this.$route.params.tyreFormDepartmentId;
      this.getDepartments();
      this.getTyreFormDepartments();
    }
    if (this.$route.params.mode == "edit") {
      this.mode.editMode = true;
      this.tyreFormDepartmentId = this.$route.params.tyreFormDepartmentId;
      this.getDepartments();
      this.getTyreFormDepartments();
    }
  },
  components: { datetime, MultiSelect },
  methods: {
    editMode() {
      this.mode.editMode = true;
      this.mode.viewMode = false;
    },
    cancel() {
      this.$router.push({
        name: "TyreDepartments",
        params: { taskId: this.$route.params.taskId },
      });
    },

    save() {
      this.$v.tyreFormDepartment.$touch();
      if (this.$v.tyreFormDepartment.$invalid) {
        console.log(
          "form is invalid",
          JSON.stringify(this.$v.tyreFormDepartment)
        );
        alert("Select atleast one Department");
      } else {
        // fetch(process.env.VUE_APP_API_HOST + "/emapp/web/secure/save/tyre/formDepartment", {
        //   method: "POST",
        //   headers: {
        //     "Content-type": "application/json",
        //   },
        //   body: JSON.stringify(this.tyreFormDepartment),
        // })

        this.apiPostSecure(
          process.env.VUE_APP_API_HOST +
            "/emapp/web/secure/save/tyre/formDepartment",
          this.tyreFormDepartment
        ).then((tyreFormDepartment) => {
          console.log(JSON.stringify(tyreFormDepartment));

          this.$router.push({
            name: "TyreDepartments",
            params: { taskId: this.$route.params.taskId },
          });
        });
      }
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },

    getDepartments() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/med/departments"
      )
        .then((response) => response.json())
        .then((data) => (this.allDepartments = data));
    },

    getTyreFormDepartments() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/tyre/formDepartment/" +
          this.tyreFormDepartmentId
      )
        .then((response) => response.json())
        .then((data) => (this.tyreFormDepartment = data));
    },
  },
  mounted() {
    this.getDepartments();
  },
};
</script>
